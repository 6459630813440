<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Nuevo"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
               
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} materiales"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0">Materiales</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>


          <Column
            field="material_code"
            header="Código"
            :sortable="true"
            headerStyle="width:9%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              {{ slotProps.data.material_code }}
            </template>
        </Column>

          <Column
            field="name"
            header="Nombre"
            :sortable="true"
            headerStyle="width:30%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.name }}
            </template>
        </Column>
        <Column
            field="quantity"
            header="Cantidad"
            :sortable="true"
            headerStyle="width:10%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Cantidad</span>
              {{ slotProps.data.quantity }}
            </template>
          </Column>
          <Column
            field="unit"
            header="Unidad"
            :sortable="true"
            headerStyle="width:10%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Unidad</span>
              {{ slotProps.data.unit }}
            </template>
          </Column>
        <Column
            field="detail"
            header="Detalle"
            :sortable="true"
            headerStyle="width:50%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Detalle</span>
              {{ slotProps.data.detail }}
            </template>
          </Column>
          

          <Column
            field="rto_nro"
            header="N° Rto."
            :sortable="true"
            headerStyle="width:9%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">N° Rto.</span>
              {{ slotProps.data.rto_nro }}
            </template>
        </Column>
        

          <Column
            field="certo_nro"
            header="N° Cert."
            :sortable="true"
            headerStyle="width:9%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">N° Cert.</span>
              {{ slotProps.data.cert_nro }}
            </template>
        </Column>
        <Column
            field="provider"
            header="Proveedor"
            :sortable="true"
            headerStyle="width:50%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Proveedor</span>
              {{ slotProps.data.provider_name }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px' }"
          header="Código"
          :modal="true"
          class="p-fluid"
        >
        <div class="formgrid grid">
 <div class="field col">
            <label for="name">Código </label>
            <InputText
              id="name"
              v-model.trim="product.material_code"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.material_code }"
            />
            <small class="p-invalid" v-if="submitted && !product.material_code"
              >Nombre es requerido.</small
            >
          </div>
           <div class="field col">
            <label for="name">Nombre </label>
            <InputText
              id="name"
              v-model.trim="product.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.name }"
            />
            <small class="p-invalid" v-if="submitted && !product.name"
              >Nombre es requerido.</small
            >
          </div>

        </div>
         
        <div class="formgrid grid">
          <div class="field col">
            <label for="quantity">Cantidad </label>
            <InputText
              id="quantity"
              v-model.trim="product.quantity"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.quantity }"
            />
            <small class="p-invalid" v-if="submitted && !product.quantity"
              >Cantidad es requerido.</small
            >
          </div>
         
          <div class="field col">
            <label for="unit">Unidad </label>
            <InputText
              id="unit"
              v-model.trim="product.unit"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.unit }"
            />
            <small class="p-invalid" v-if="submitted && !product.unit"
              >Unidad es requerido.</small
            >
          </div>
          </div>  
                    <div class="field">
            <label for="detail">Detalle </label>
            <InputText
              id="detail"
              v-model.trim="product.detail"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.detail }"
            />
            <small class="p-invalid" v-if="submitted && !product.detail"
              >Detalle es requerido.</small
            >
          </div>
           <div class="formgrid grid">
          <div class="field col">
            <label for="detail">Rto Nº</label>
            <InputText
              id="detail"
              v-model.trim="product.rto_nro"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.rto_nro }"
            />
            <small class="p-invalid" v-if="submitted && !product.rto_nro"
              >Detalle es requerido.</small
            >
          </div>
          <div class="field">
            <label for="detail">Cert. Nº</label>
            <InputText
              id="detail"
              v-model.trim="product.cert_nro"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.cert_nro }"
            />
            <small class="p-invalid" v-if="submitted && !product.cert_nro"
              >Detalle es requerido.</small
            >
          </div>
          </div>
          <div class="formgrid grid">
                    <div class="field col">
            <label for="name">Proveedor </label>
            <Dropdown
              id="provider"
              v-model="product.provider"
              :options="providers"
              filter="true"
              filterBy="name"
              filterPlaceholder="Buscar..."
              optionLabel="name"
              optionValue="id"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.provider }"
            />
            <!-- <InputText
              id="provider"
              v-model.trim="product.provider"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.provider }"
            /> -->
            <small class="p-invalid" v-if="submitted && !product.provider"
              >Proveedor es requerido.</small
            >
          </div>
          <div class="field col">
            <label for="detail">Precio de Compra </label>
            <InputText
              id="detail"
              v-model.trim="product.buy_price"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.buy_price }"
            />

          </div>
          
</div> 
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmación"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Eliminaras a <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product">Seguro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import CrudService from "../services/crud.service";

export default {
  data() {
    return {
      routeName: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      validationErrors: null,
      providers: []
    };
  },
   
  created() {
    // get route name
    this.routeName = this.$route.name;
     
    this.initFilters();
  },
  mounted() {
     CrudService.getCRUD('api/materiales').then(
      (data) => (this.products = data)
    );
    CrudService.getCRUD('api/proveedor').then(
      (data) => (this.providers = data)
    );

  },
  methods: {
    giveMeName(id) {
      let exist = this.providers.find(provider => provider.id === id);

      if(!exist) {
        return ' - ';
      }else {
        return exist.name;
      }
    },
    afipType(value) {
      if (value == 1) {
        return "Responsable Inscripto";
      }
      if (value == 2) {
        return "Monotributista";
      }
      if (value == 3) {
        return "Exento";
      }
      if (value == 4) {
        return "Consumidor Final";
      }

      return " - ";
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    displayErrors(errors) {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          CrudService.updateCRUD('api', 'materiales', this.product).then(
            (data) => {

            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);

            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito!",
                detail: "Actualizado correctamente.",
                life: 3000,
              });
              this.products[this.findIndexById(this.product.id)] = this.product;
              this.productDialog = false;
              this.product = {};
            }
          });
        } else {
          CrudService.createCRUD('api', 'materiales', this.product).then(
            (data) => {
              
            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);
              
              } else {
                this.products.push(this.product);
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Creado correctamente.",
                  life: 3000,
                });
                this.productDialog = false;
                this.product = {};
              }
            }
          );
        }
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      
      CrudService.deleteCRUD('api', 'materiales', this.product.id).then(
        (data) => {

          this.products = this.products.filter((val) => val.id !== this.product.id);

          this.$toast.add({
            severity: "success",
            summary: "Éxito!",
            detail: "Eliminado correctamente.",
            life: 3000,
          });
        }
      );

      this.deleteProductDialog = false;
      this.product = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Éxito!",
        detail: "Eliminado correctamente.",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
